import React, { useState, useEffect } from "react";
import Layout from "../../components/Layout";
import Cookies from "js-cookie";

const Survey = () => {
  // State for managing username/password input
  const [username, setUsername] = useState("");
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [showMessage, setShowMessage] = useState(false);

  // Credentials (In a real-world scenario, you'd likely authenticate via an API or a backend)
  const correctUsername = "412661"; 
  const COOKIE_NAME = "survey_authenticated";
  const COOKIE_EXPIRY_DAYS = 270;  // Expiry in days (9 months)

  // Function to handle login form submission
  const handleLogin = (event) => {
    event.preventDefault();
    if (username === correctUsername) {
      // Mark the user as authenticated and store cookie
      setIsAuthenticated(true);
      Cookies.set(COOKIE_NAME, "true", { expires: COOKIE_EXPIRY_DAYS });

      // Store the first visit date in a cookie
      const firstVisit = new Date().toISOString();
      Cookies.set("first_visit_date", firstVisit, { expires: COOKIE_EXPIRY_DAYS });
    } else {
      alert("Incorrect username or password.");
    }
  };

  // Check if the user is authenticated and whether the visit is within the last 9 months
  useEffect(() => {
    const isLoggedIn = Cookies.get(COOKIE_NAME) === "true";
    if (isLoggedIn) {
      setIsAuthenticated(true);
    }

    // Check the first visit date and see if 9 months have passed
    const firstVisitDate = Cookies.get("first_visit_date");
    if (firstVisitDate) {
      const firstVisitTime = new Date(firstVisitDate).getTime();
      const currentTime = new Date().getTime();
      const diffInMs = currentTime - firstVisitTime;
      const diffInDays = diffInMs / (1000 * 3600 * 24); // Convert from milliseconds to days
      const daysInNineMonths = 270; // Approx. 9 months in days

      if (diffInDays < daysInNineMonths) {
        setShowMessage(true); // Show message if less than 9 months have passed
      }
    }
  }, []);

  // Dynamically load the Typeform embed script when authenticated
  useEffect(() => {
    if (isAuthenticated && !showMessage) {
      const script = document.createElement('script');
      script.src = "//embed.typeform.com/next/embed.js";
      script.async = true;
      document.body.appendChild(script);
    }
  }, [isAuthenticated, showMessage]);

  return (
    <Layout>
      <div className="flex justify-center items-center min-h-screen bg-gray-100">
        {!isAuthenticated ? (
          <div className="bg-white p-10 rounded-lg shadow-lg w-full sm:w-96 lg:w-1/3 max-w-lg">
            <h2 className="text-3xl font-semibold text-center text-gray-800 mb-6">Login to Access the Survey</h2>
            <form onSubmit={handleLogin}>
              <div className="mb-6">
                <label htmlFor="username" className="block text-sm font-medium text-gray-700">Enter Passcode to Access the Survey</label>
                <input
                  type="text"
                  id="username"
                  value={username}
                  onChange={(e) => setUsername(e.target.value)}
                  required
                  className="w-full mt-2 px-4 py-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500"
                />
              </div>
              <button
                type="submit"
                className="w-full mt-2 px-4 py-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2"
                >
                Login
              </button>
            </form>
          </div>
        ) : (
          <div>
            {showMessage ? (
              <div className="text-center p-4 rounded-lg mb-4">
                <p>You can only visit the survey once per year. Please come back next year.</p>
              </div>
            ) : (
              <div data-tf-live="01JCRCHNSPP5MXV1V06HSTF29M"></div>
            )}
          </div>
        )}
      </div>
    </Layout>
  );
};

export default Survey;
